<template>
  <div>
    <b-row>
      <b-col cols="12">
        <dashboard-component-form />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BLink } from 'bootstrap-vue';

import DashboardComponentForm from './components/DashboardComponentForm.vue';

export default {
  name: 'dashboard',
  components: {
    BCol,
    BRow,
    BCard,
    BCardText,
    BLink,
    DashboardComponentForm,
    
  },
};
</script>

<style></style>
