<template>
   <dashboard />
</template>

<script>

import Dashboard from './dashboard/Dashboard.vue';

export default {
  components: {
    Dashboard,

  },

};
</script>

