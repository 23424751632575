<template>
  <b-card
    no-body
    class="card-revenue-budget h-100"
  >
    <b-row class="mx-0">
      <b-col
        md="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h6 class="mb-50 mb-sm-0">
            Conforme x Não conforme
          </h6>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer" />
              <span style="font-size: 12px">Conforme</span>
            </div>
            <div class="d-flex align-items-center">
              <span class="bullet bullet-danger svg-font-small-3 mr-50 cursor-pointer" />
              <span style="font-size: 12px">Não conforme</span>
            </div>
          </div>
        </div>
        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          type="bar"
          height="auto"
          :options="revenueReport.chartOptions"
          :series="revenueReport.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'dashboad-component-card-analytic',
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props:{
     propData:{
       type: Object,
       default: {},
     }
  },
  data() {
    return {
      revenue_report: {},
      revenueReport: {
        series: this.propData.series,
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: this.propData.categories,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.86rem',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.success, $themeColors.danger],
          plotOptions: {
            bar: {
              columnWidth: '17%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.86rem',
              },
            },
          },
        },
      },


    }
  },
}
</script>
