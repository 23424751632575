<template>
  <b-row>
    <b-col lg="4" md="4" sm="12" xs="12">
      <statistic-card-horizontal
        icon="CalendarIcon"
        :statistic="reportsUpToDate.length"
        statisticTitle="Em Dia"
        color="success"
      />
    </b-col>
    <b-col lg="4" md="4" sm="12" xs="12">
      <statistic-card-horizontal
        icon="CalendarIcon"
        :statistic="reportsNearExpiration.length"
        statisticTitle="A Vencer"
        color="warning"
      />
    </b-col>
    <b-col lg="4" md="4" sm="12" xs="12">
      <statistic-card-horizontal
        icon="CalendarIcon"
        :statistic="overdueReports.length"
        statisticTitle="Vencidos"
        color="danger"
      />
    </b-col>
    <b-col class="mt-1" lg="7" md="12" sm="12">
      <b-card class="h-100">
        <b-row class="justify-content-md-end">
          <b-col col lg="1">
            <b-button v-b-modal.modal-1 variant="flat-primary" class="btn-icon">
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </b-col>
        </b-row>
        <e-chart-line
          :title="selectedQuestion.description"
          v-if="!loading"
          :optionData="optionData"
        />
      </b-card>
    </b-col>
    <b-col lg="5" md="12" sm="12" class="mt-1">
      <dashboad-component-card-analytic v-if="!loading" :propData="propData" />
    </b-col>

    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="Gerar gráfico"
      @ok="saveDashboard"
      ok-title="Salvar"
      no-close-on-backdrop
    >
      <b-card-text>
        <b-row align-h="end">
          <b-col cols="12" class="ml-1" v-if="company.role.name != 'Client'">
            <h5>Selecione o Cliente</h5>
            <b-form-group>
              <v-select
                class="selectExample mt-0 select-size-sm"
                v-model="selectedClient"
                :options="clientOptions"
                label="Cliente"
                :getOptionLabel="(item) => item.client_name"
                :reduce="(item) => item"
                placeholder="Selecione o cliente"
                @input="filterByClient"
              >
                <template v-slot:option="item">
                  <div class="select-responsible">
                    <div>
                      {{ item.client_name }}
                    </div>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="company.role.name != 'Client' && selectedClient"
            cols="12"
            class="ml-1"
          >
            <h5>Selecione o equipamento desejado</h5>
            <b-form-group>
              <v-select
                class="selectExample mt-0 select-size-sm"
                v-model="selectedEquipment"
                :options="equipmentOptions"
                label="Equipamento"
                :getOptionLabel="(item) => item.description"
                :reduce="(item) => item.equipment_id"
                placeholder="Selecione o equipamento"
                @input="filterByQuestions"
              >
                <template v-slot:option="item">
                  <div class="select-responsible">
                    <div>
                      {{ item.description }}
                    </div>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col v-if="company.role.name == 'Client'" cols="12" class="ml-1">
            <h5>Selecione o equipamento desejado</h5>
            <b-form-group>
              <v-select
                class="selectExample mt-0 select-size-sm"
                v-model="selectedEquipment"
                :options="equipmentOptions"
                label="Equipamento"
                :getOptionLabel="(item) => item.description"
                :reduce="(item) => item.equipment_id"
                placeholder="Selecione o equipamento"
                @input="filterByQuestions"
              >
                <template v-slot:option="item">
                  <div class="select-responsible">
                    <div>
                      {{ item.description }}
                    </div>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="ml-1" v-if="selectedEquipment">
            <h5>Selecione o parametro que deseja acompanhar</h5>
            <b-form-group>
              <v-select
                class="selectExample mt-0 select-size-sm"
                v-model="selectedQuestion"
                :options="questionsOptions"
                label="Questão"
                :getOptionLabel="(item) => item.description"
                :reduce="(item) => item"
                placeholder="Selecione a questão"
                @input="generateGraphic"
              >
                <template v-slot:option="item">
                  <div class="select-responsible">
                    <div>
                      {{ item.description }}
                    </div>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormGroup,
  BModal,
  BButton,
} from 'bootstrap-vue';
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue';
import DashboadComponentCardAnalytic from '../components/DashboadComponentCardAnalytic.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
export default {
  name: 'dashboard-component-form',
  components: {
    BCol,
    BRow,
    BCard,
    BCardText,
    AppEchartLine,
    BFormGroup,
    DashboadComponentCardAnalytic,
    BModal,
    BButton,
    ToastificationContent,
    StatisticCardHorizontal,
  },
  data() {
    return {
      loading: false,
      reportsUpToDate: [],
      reportsNearExpiration: [],
      overdueReports: [],
      conforme: [],
      naoConforme: [],
      questionsConforme: [],
      questionsNaoConforme: [],
      propData: {
        series: [
          {
            name: 'Conformes',
            data: [],
          },
          {
            name: 'Não conformes',
            data: [],
          },
        ],
        categories: [],
      },
      optionData: {
        title: '',
        xAxisData: [],
        series: [],
      },
      selectedEquipment: null,
      equipmentOptions: [],
      selectedQuestion: {
        description: 'Descrição',
        question_id: null,
      },
      questionsOptions: [],
      graphicValues: [],
      allGraphicData: [],
      selectedClient: null,
      clientOptions: [
        {
          selectedClient: 1,
          client_name: 'Todos',
          description: 'Todos',
        },
      ],
      datasTemp: [],
    };
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  watch: {
    selectedClient(val) {
      this.setCards(val.client_id);
    },
  },
  mounted() {
    this.getAllDocumentsOptions();
  },
  methods: {
    filterByClient() {
      this.equipmentOptions = [];
      this.$store
        .dispatch('getDashboardFilter', {
          client_id: this.selectedClient.client_id,
          company_id: this.company.id,
        })
        .then((resp) => {
          const arrayUniqueByKey = [
            ...new Map(
              resp.map((item) => [item['equipment_id'], item])
            ).values(),
          ];
          arrayUniqueByKey.map((item) => {
            this.equipmentOptions.push({
              equipment_id: item.equipment_id,
              description: item.equipment_description,
            });
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível carregar o seu dashboard',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
    async getDashboardInit() {
      await this.$store
        .dispatch('getDashboard')
        .then((resp) => {
          resp.map((item, i) => {
            this.selectedClient = item.client_id;
            this.selectedEquipment = item.equipment_id;
            this.selectedQuestion.question_id = item.question_id;
            this.selectedQuestion.description = item.question_description;
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível carregar seu dashboard',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });

      await this.filterByQuestions();
      await this.generateGraphic();
    },
    getAllDocumentsOptions() {
      this.$store
        .dispatch('getAllDashboard')
        .then((resp) => {
          const groups = [];
          resp.map((item) => {
            this.selectedClient = item.client_id
            if (
              (item.value && item.value.toUpperCase() === 'CONFORME') ||
              (item.value && item.value.toUpperCase() === 'SATISFATÓRIO')
            ) {
              this.questionsConforme.push({
                order_service_form_id: item.order_service_form_id,
                description: item.description,
                answer: item.value,
                data: item.data_formulario,
                equipment_id: item.equipment_id,
              });
            } else if (
              (item.value && item.value.toUpperCase() === 'NÃO CONFORME') ||
              (item.value && item.value.toUpperCase() === 'NÃO SATISFATÓRIO')
            ) {
              this.questionsNaoConforme.push({
                order_service_form_id: item.order_service_form_id,
                description: item.description,
                answer: item.value,
                data: item.data_formulario,
                equipment_id: item.equipment_id,
              });
            }
            if (item.type == 10 && item.value && this.isNumeric(item.value)) {
              const question = {
                description: item.description,
                answer: item.value,
                data: item.data_formulario,
                equipment_id: item.equipment_id,
                answer_id: item.answer_id,
                question_id: item.question_id,
              };
              groups.push(question);
            }
          });
          this.allGraphicData = groups;
          const arrayUniqueByKey = [
            ...new Map(
              resp.map((item) => [item['equipment_id'], item])
            ).values(),
          ];
          arrayUniqueByKey.map((item) => {
            this.equipmentOptions.push({
              equipment_id: item.equipment_id,
              description: item.equipment_description,
            });
          });

          const arrayUniqueByKeyclient = [
            ...new Map(resp.map((item) => [item['client_id'], item])).values(),
          ];
          arrayUniqueByKeyclient.map((item) => {
            this.clientOptions.push({
              client_id: item.client_id,
              client_name: item.client_name,
              description: item.client_name,
            });
          });
          if (this.company.role.name == 'Client') {
            this.getDashboardInit();

          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível carregar os dados',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    groupBy(objectArray, param) {
      let arrayTemp = [];
      let vm = this;
      let tmp = [];
      objectArray.reduce(function (object, item) {
        if (!object[item.order_service_form_id]) {
          object[item.order_service_form_id] = 1;
          if (!vm.datasTemp.includes(item.data)) {
            vm.datasTemp.push(item.data);
            tmp.push({ value: item.answer, data: item.data });
          }
        } else {
          object[item.order_service_form_id]++;
        }

        arrayTemp = object;
        return object;
      }, {});
      if (param.toUpperCase() == 'NÃO CONFORME') {
        var resp = this.makeListNegative(Object.values(arrayTemp));
      } else {
        resp = Object.values(arrayTemp);
      }
      return resp;
    },

    makeListNegative(array) {
      return array.map((x) => x * -1);
    },

    populateQuestionsOptions() {
      this.questionsOptions = [];
      const arrayUniqueByKey = [
        ...new Map(
          this.graphicValues.map((item) => [item['description'], item])
        ).values(),
      ];
      arrayUniqueByKey.map((item) => {
        this.questionsOptions.push(item);
      });
    },
    filterByQuestions() {
      this.graphicValues = [];
      const itensTemp = [];
      this.datasTemp = [];
      const questionsConformeTemp = [];
      const questionsNaoConformeTemp = [];
      this.allGraphicData.map((item) => {
        if (item.equipment_id == this.selectedEquipment) {
          itensTemp.push(item);
        }
      });
      this.questionsConforme.map((item) => {
        if (item.equipment_id == this.selectedEquipment) {
          questionsConformeTemp.push(item);
        }
      });
      this.questionsNaoConforme.map((item) => {
        if (item.equipment_id == this.selectedEquipment) {
          questionsNaoConformeTemp.push(item);
        }
      });
      itensTemp.sort((a, b) => new Date(a.data) - new Date(b.data));
      questionsConformeTemp.sort((a, b) => new Date(a.data) - new Date(b.data));
      questionsNaoConformeTemp.sort(
        (a, b) => new Date(a.data) - new Date(b.data)
      );
      this.graphicValues = itensTemp;
      this.populateQuestionsOptions();
      this.conforme = this.groupBy(questionsConformeTemp, 'Conforme');
      this.naoConforme = this.groupBy(questionsNaoConformeTemp, 'Não conforme');
    },
    resetArrays() {
      this.optionData.xAxisData = new Array();
      this.optionData.series = new Array();
    },
    async generateGraphic() {
      this.loading = true;
      this.$emit('setLoading', true);
      this.optionData.title = this.selectedQuestion.description;
      const options = await this.fillDataGraphic();

      this.propData.categories = options.dates;
      this.optionData.xAxisData = options.axisX;
      this.optionData.series = options.axisY;

      this.propData.series[0].data = options.obj.obj1;
      this.propData.series[1].data = options.obj.obj2;
      this.loading = false;
    },
    fillDataGraphic() {
      const axisX = [];
      const axisY = [];
      const datesArray = [];
      var obj = {};
      this.graphicValues.map((item) => {
        if (item.question_id == this.selectedQuestion.question_id) {
          const dateForm = this.dateFormatter(item.data, 'DD/MM');

          axisX.push(dateForm);
          axisY.push(item.answer);
          obj = {
            obj1: this.conforme,
            obj2: this.naoConforme,
          };
        }
      });
      this.datasTemp.map((item) => {
        const dateCategory = this.dateFormatter(item, 'DD/MM');
        datesArray.push(dateCategory);
      });
      return {
        axisX: new Array(...axisX),
        axisY: new Array(...axisY),
        dates: new Array(...datesArray),
        obj: obj,
      };
    },

    isNumeric(str) {
      if (typeof str != 'string') return false;
      return !isNaN(str) && !isNaN(parseFloat(str));
    },

    saveDashboard() {
      this.$store
        .dispatch('saveDashboard', {
          user_id: this.user.id,
          equipment_id: this.selectedEquipment,
          company_id: this.company.id,
          question_description: this.selectedQuestion.description,
          question_id: this.selectedQuestion.question_id,
        })
        .then((resp) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Dashboard salvo com sucesso',
              icon: 'CheckIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível salvar o seu dashboard',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
    setCards(val) {
      this.reportsUpToDate = [];
      this.reportsNearExpiration = [];
      this.overdueReports = [];
      console.log(val, this.selectedClient);
        this.$store
          .dispatch('getAllOrderService', {
            client_id:  val ? val : this.selectedClient,
            company_id: this.company.id,
          })
          .then((resp) => {
            resp.map((item) => {
              console.log(item.due_date)

              if(item.due_date != null){
              console.log(item)
              let d = this.moment(item.due_date).format(
                'yyyy-MM-DD hh:mm'
              );
              var endDateMoment = this.moment(d)
                .subtract(1, 'months')
                .format('yyyy-MM-DD hh:mm');
              var currentDay = this.moment().format('yyyy-MM-DD hh:mm');

              if (item.due_date) {
                console.log(currentDay, endDateMoment, d);
                if (currentDay < d && currentDay > endDateMoment) {
                  this.reportsNearExpiration.push(item);
                } else if (currentDay < d && currentDay < endDateMoment) {
                  this.reportsUpToDate.push(item);
                } else if (currentDay > d) {
                  this.overdueReports.push(item);
                }
              }
              }
            });
          })
          .catch((error) => {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: 'Não foi possível carregar os documentos',
            //     text: `${error}`,
            //     icon: 'XIcon',
            //     variant: 'danger',
            //   },
            // });
          });
    },
  },
};
</script>
